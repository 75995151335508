const particleOptions = {
  fullScreen: {
    enable: true,
    zIndex: 1,
  },
  particles: {
    color: {
      value: '#ffffff',
    },
    move: {
      attract: {
        rotate: {
          x: 600,
          y: 600,
        },
      },
      enable: true,
      path: {},
      outModes: {
        bottom: 'out',
        left: 'out',
        right: 'out',
        top: 'out',
      },
      random: true,
      speed: 1,
    },
    number: {
      density: {
        enable: true,
      },
      value: 40,
    },
    opacity: {
      random: {
        enable: true,
      },
      value: {
        min: 0,
        max: 0.5,
      },
      animation: {
        enable: true,
        speed: 1,
      },
    },
    size: {
      random: {
        enable: true,
      },
      value: {
        min: 1,
        max: 3,
      },
      animation: {
        speed: 4,
        minimumValue: 0.3,
      },
    },
  },
};

export default particleOptions;
