<template>
  <div>
    <section class="l-modal l-modal-candidates">
      <transition name="menu-fade" >
        <div class="menu-bk" >
          <div class="menu-c" >
            <div class="wrap" >
              <section class="menu-body" >
                <slot name="body">
                  <nav class="menu-nav" @focusout="close"
        tabindex="0">
                    <ul class="menu-list">
                      <li class="menu-item">
                        <router-link :to="{ name: 'home' }">Home</router-link>
                      </li>
                      <li class="menu-item">
                        <router-link :to="{ name: 'approach' }">The Approach</router-link>
                      </li>
                      <li class="menu-item">
                        <router-link :to="{ name: 'work' }">The Work</router-link>
                      </li>
                      <li class="menu-item">
                        <router-link :to="{ name: 'markets' }">The Markets</router-link>
                      </li>
                      <li class="menu-item">
                        <router-link :to="{ name: 'journey' }">The Journey</router-link>
                      </li>
                      <li class="menu-item">
                        <router-link :to="{ name: 'exchange' }">The Exchange</router-link>
                      </li>
                      <li class="menu-item">
                        <a @click="goToContact">Contact / Connect</a>
                      </li>
                    </ul>
                  </nav>
                </slot>
              </section>
            </div>
          </div>
        </div>
      </transition>
    </section>
  </div>
</template>

<script>
export default {
  name: 'TheMenu',
  props: {
    menuIsOpen: {
      type: Boolean,
      require: true
    },
  },
  data() {
    return {
      menudata: this.menuIsOpen,
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    open() {
      this.$emit('open')
    },
    goToContact() {
      this.$emit('go-to-contact')
    },
    closeOutside (e) {
      if (this.$el.contains(e.target)) {
        this.close()
      }
    }
  },
  mounted () {
    document.addEventListener('click', this.closeOutside)
  },
  beforeDestroy () {
    document.removeEventListener('click',this.closeOutside)
  }
};
</script>
