<template>
  <footer class="l-footer" data-scroll-section>
    <div class="text-c" data-scroll="fade-up">
      <p class="text"><b>Ascentador Consulting USA, Limited Liability Company No. A069435.</b></p>
      <p class="text">US Federal Tax ID (EIN) No. 46-1681888. Headquarters: 200 Park Avenue, Suite 1700, New York, New York 10166 USA.</p>
      <p class="text">Registered Office: 1399 Tulip Lane, Baldwin, Wisconsin 54002 USA. <a href="mailto:info@ascentador.com">info@ascentador.com</a></p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'TheFooter',
};
</script>
