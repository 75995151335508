<template>
  <div id="backgrounds" data-scroll-section>
    <figure data-scroll :data-scroll-speed="1" data-scroll-position="top right" data-scroll-delay="1" class="image-c light-01"><img alt="bk" :src="require(`@/assets/bk/light-02.png`)" /></figure>
    <figure data-scroll :data-scroll-speed="-5" class="image-c light-02"><img alt="bk" :src="require(`@/assets/bk/light-01.png`)" /></figure>
    <!-- <figure class="image-c light-03"><img alt="bk" :src="require(`@/assets/bk/light-03.png`)" /></figure> -->
    <figure data-scroll :data-scroll-speed="2" class="image-c light-04"><img alt="bk" :src="require(`@/assets/bk/light-04.png`)" /></figure>
  </div>
</template>

<script>
export default {
  name: 'Backgrounds',
};
</script>
