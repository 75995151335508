<template>
  <div id="footer-background" data-scroll-section>
    <div class="img-wrapper">
      <img data-scroll :data-scroll-speed="-4" class="img" :src="require(`@/assets/bk/city/04-bk-city-clouds.svg`)" />
    </div>
    <div class="img-wrapper">
      <img data-scroll :data-scroll-speed="-3" class="img" :src="require(`@/assets/bk/city/03-bk-city-mountains.svg`)" />
    </div>
    <div class="img-wrapper">
      <img data-scroll :data-scroll-speed="-2" class="img" :src="require(`@/assets/bk/city/02-bk-city-back-buildings.svg`)" />
    </div>
    <div class="img-wrapper">
      <img data-scroll class="img" :src="require(`@/assets/bk/city/01-bk-city-front-buildings.svg`)" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheFooterBackground',
};
</script>
