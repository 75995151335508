import Vue from 'vue';
// seo
import VueMeta from 'vue-meta'
Vue.use(VueMeta)

//# Swiper
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/swiper-bundle.css'; // Swiper Styles
import SwiperCore, { EffectFade } from 'swiper';
SwiperCore.use([EffectFade]);
Vue.use(VueAwesomeSwiper);

//# Particles
import Particles from "particles.vue";
Vue.use(Particles);